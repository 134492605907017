@media screen and (max-width: 480px) {
  col-md-6 {
    width: 40%;
  }
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.searchbtn:hover {
  font-variant: unset;
}
.filter-box {
  max-width: 430px;
}
.asd {
}
.text-field {
  width: 45%;
}
.text-field .MuiFormLabel-root {
  color: black;
  transform: translate(13px, 16px) scale(1);
  font-size: 20px;
}
.txt-field-select {
  width: unset;
}
.txt-field-select select {
  padding: 12px 14px;
  font-size: 20px;
}
.text-field fieldset {
  border-color: black;
}
.text-field .Mui-focused {
  border-color: black;
  color: black;
}
.end-icon {
  color: var(--light-border-color);
  position: relative;
}
.tooltiptext {
  position: absolute;
  /* opacity: 0; */
  visibility: hidden;
  white-space: break-spaces;

  margin: -14px 0 0 -100px;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.4;
  background-color: rgba(0, 0, 0, 0.8);
  width: 200px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  bottom: calc(100% + 8px);
  left: 50%;
  z-index: 10;
  transition: all 0.3s ease;
}
.tooltiptext::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  left: 50%;
  top: 100%;
  margin-left: -5px;
}
.end-icon:hover {
  color: black;
}
.show-tooltip:hover .tooltiptext {
  /* color: black; */
  visibility: visible;
}
.text-field .MuiOutlinedInput-adornedEnd {
  padding-right: 0;
}
.text-field .text-field-input {
  border-radius: 30px;
  border-color: black;
  /* border: black 0.5px solid; */
}
.text-field .text-field-input input {
  padding: 14px 14px;
}
.txt-field-select-mobile .MuiSelect-icon {
  color: #fff;
}
.txt-field-select-mobile fieldset {
  border-color: white !important;
  border-radius: 30px;
  /* height: 20px; */
}

.txt-field-select-mobile select {
  color: #fff;
  padding: 10px 6px;
}
.side-layout-search .my-input {
  border: 1px solid black;
}
.side-layout-search .my-input input::placeholder {
  font-size: 19px;
  text-align: left;
}
.input-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.input-tag .my-input {
  width: 600px;
  /* color: white; */
  text-align: center;
  font-size: 30px;
  background-color: white;
  border-radius: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.input-tag .my-input input {
  /* color: white; */
  text-align: center;
}
@keyframes zooom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}
.input-tag .my-input.Mui-error {
  border: 1px solid rgb(201, 32, 32);
  /* transform: scale(1.05); */
  animation: zooom 0.4s ease-out 2;
}

.input-tag .my-input input::placeholder {
  /* color: white; */
  color: var(--light-border-color);
}
@media screen and (max-width: 900px) {
  .text-field .MuiFormLabel-root {
    font-size: 16px;
  }
  .txt-field-select select {
    font-size: 16px;
  }
  .side-layout-search .my-input input::placeholder {
  }

  .input-tag .my-input input::placeholder {
    font-size: 20px;
  }
  .input-tag .my-input input {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 20px;
  }
}
