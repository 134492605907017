:root {
  --bg-primary: #2596be;
  --bg-dark: #1a7191;
  --light-yellow: #f7c342;
  --dark-yellow: #bc942d;
  --light-border-color: #c4c4c4;
  --white: #ffffff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0px;
}
body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #232427;
}
@media screen and (max-width: 480px) {
  .app {
    width: 100%;
    overflow: hidden;
  }
}
