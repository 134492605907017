.buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin: 40px 0px;
}
.badge-secondary {
  cursor: pointer;
}
.radio {
  color: var(--bg-primary) !important;
}
.my-z-indx {
  z-index: 4;
}
.scroll-fixed-box {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 10px;
  background-color: white;
}
.mobile-filters {
  background-color: var(--bg-primary);
  padding-bottom: 10px;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  display: none;
  justify-content: center;
}
.filter-container {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  max-width: 430px;
  border-radius: 15px;
}
.my-border-box {
  border: 1px solid var(--light-border-color);
  border-radius: 15px;
}
.result-box-title {
  position: relative;
  font-size: 24px;
  line-height: 29px;
  color: #353d50;
  margin-bottom: 16px;
}
.rows {
  display: flex;
  flex-wrap: wrap;
}
.leftside {
  flex: 1;
}

.rightside {
  flex: 0.5;
}
.col-md-7 {
  display: flex;
}
@media screen and (max-width: 800px) {
  .result-row {
    padding: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .mobile-filters {
    display: block;
  }
  .rightbar {
    display: none;
  }
  .leftbar {
    width: 100% !important;
    max-width: 100%;
    flex: unset;
  }

  .leftbar {
    padding: 0;
  }
  .mobile-container {
    padding: 0;
  }
  .result-row {
    padding: 0 !important;
  }
  .words-card {
    margin-top: 0;
    margin-bottom: 0 !important;
    border: none;
  }
}
@media screen and (max-width: 480px) {
  .paper {
    margin: 10px 0px;
  }
  .leftside {
    align-items: center;
    justify-content: center;
  }
}
