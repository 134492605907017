.home {
  background-color: #2596be;
  color: #fff;
}

.heading {
  padding-top: 20px;
  font-size: 3rem;
}
.span-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 0.875rem;
}
.p-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 20px;
}

.free-space {
  height: 20px;
}
.filter {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}

.filter .MuiPaper-root {
  background-color: #fff !important;
}
.searchicon {
  color: #fff;
}
.searchicon:hover {
  color: blue;
}
@media screen and (max-width: 480px) {
  .input-tag {
    width: 100%;
  }
  .input-tag Input {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .filter {
    width: 100%;
    display: block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
  }
  .heading {
    padding-top: 60px;
    font-size: 30px;
  }
  .span-tag {
    display: none;
  }
  .p-tag {
    text-align: center;
    font-size: 14px;
  }
}
