@media screen and (max-width: 480px) {
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 20px;
  }

  .card {
    width: 220px;
  }
  .card {
    width: 200px;
  }
  .rightside {
    width: 100vw;
  }
}
