.words-card {
}
.card-heading {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
}
.word-box {
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  line-height: 29px;
  font-weight: 400;
  font-size: 24px;
  margin: 5px;
  margin-right: 16px;
  text-decoration: none;
  color: #353d50;
  padding: 5px 9px;
  background: #e3e3e3;
  border-radius: 0 10px !important;
  position: relative;
  cursor: pointer;
}
.result-card-body {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 24px 18px 24px 18px;
  flex-wrap: wrap;
}
.popup-body-heading {
  font-size: 24px;
  line-height: 29px;
}
.inner-card {
  width: auto !important;
}
.inner-modal-card-header {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.popup-line {
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 5px;
}
.words-card .card-header {
  background: #2596be;
  color: white;
}
.loader {
  border: 15px solid #2596be;
  border-radius: 50%;
  border-top: 15px solid #fbfbfb;
  border-right: 15px solid #fbfbfb;
  width: 70px;
  height: 70px;
  background: #f7c342;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
