.clr {
  background-color: var(--bg-primary);
}
.nav-link {
  color: #fff;
  font-size: 1.3rem;
  margin-left: 20px;
  cursor: pointer;
}
.nav-link:hover {
  color: #fff;
}
.nav-img {
  width: 100px;
  height: 60px;
  cursor: pointer;
}
.drawer-img {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-header {
  background-color: #2596be;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-list {
  display: flex;
  flex-direction: column;
  text-align: center;
}
small:hover {
  background-color: #2596be;
  color: #fff;
  cursor: pointer;
}
.drawer {
  overflow: scroll;
}
small {
  border-bottom: 1px solid #2596be;
}

@media screen and (max-width: 480px) {
  .ok {
    display: none !important;
  }
}
