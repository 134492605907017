.footer {
  margin-top: 30px;
  background-color: #2596be;
  color: #fff;
  bottom: 0px !important;
}
.row {
  padding: 40px;
}

.words-card-spacing {
  padding: 20px;
}

ul > li {
  list-style: none;
  cursor: pointer;
  color: "white";
}
.separate-bottom-links {
  line-height: 16px;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }
  .footer img {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media screen and (max-width: 480px) {
  .row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
