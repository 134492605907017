@media screen and (max-width: 480px) {
  .rows {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .right {
    flex: 1;
  }
}
.card {
  width: 100%;
}
.rows {
  display: flex;
  flex-wrap: wrap;
}
.left {
  flex: 2;
}
.right {
  flex: 1;
  margin-left: 20px;
}
