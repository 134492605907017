.paper {
  padding: 20px;
  border-radius: 15px !important;
}
.paper2 {
  padding: 20px;
  border-radius: 15px !important;
}
.rows {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.left {
  flex: 0.6;
}
.right {
  flex: 0.3;
}

@media screen and (max-width: 480px) {
  .paper {
    width: 100%;
    padding: 20px;
  }
  .paper2 {
    width: 100%;
    margin-top: 10px;
    padding: 20px;
  }
  .col-md-12 {
    width: 100%;
  }
  .homepage {
    width: 100%;
  }
  .col-md-8 {
    margin-bottom: 20px;
  }
  .col-md-4 {
    margin-bottom: 20px;
  }
  .right {
    flex: 1;
    margin: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  .left {
    padding-left: 0;
    padding-right: 0;
  }
  .paper2 {
  }
}
