.list-group-item {
  list-style: none;
  color: black;
}
.top-title p {
  font-style: italic;
  font-size: large;
}
.card {
  width: 100%;
  margin-top: 10px;
}
.list-card .card-header:first-child {
  border-radius: 10px 10px 0px 0px;

  background-color: var(--bg-primary) !important;
}
.list-card {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}
.card-list-group {
  margin-bottom: 0;
}
.card-list-group .my-list-group-item {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 10px;
  padding-top: 10px;
}
.card-list-group a {
  color: unset;
  list-style: none;
  list-style-type: none;
}
.card-list-group a:last-child .my-list-group-item {
  border-bottom: none;
}
.card-list-group > .my-list-group-item:last-child {
  border-bottom: none;
}
.img-size {
  width: 150px;
  height: 150px;
  margin-top: 10px;
}
.top-left {
  width: 100%;
  /* margin-left: 40px; */
  display: flex;
  justify-content: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.blog-heading {
  font-size: 18px;
  text-decoration: none;
  color: black;
  margin-top: 8px;
}
.img-tag {
  flex: 0.2;
  text-align: center;
}
.word-finder {
  flex: 0.7;
  margin-left: 20px;
}
.word-finder h1 {
  font-size: 2rem;
}
@media screen and (max-width: 980px) {
  .img-size {
    width: 110px;
    height: 110px;
  }
}
@media screen and (max-width: 480px) {
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 20px;
  }
  li {
    font-size: 14px;
  }
  .card {
    width: 200px;
  }
  .top-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 20px;
  }
  .word-finder {
    margin-left: 0;
  }
  .img-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}
