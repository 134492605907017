.rows {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.leftside {
  flex: 0.7;
  margin: 20px;
}
.rightone {
  flex: 0.3;
}
